<template>
	<div class="AddCirle">
		<w-navTab titleText="发布义警圈"></w-navTab>
		<div class="container">
			<div class="head-text">关联活动</div>
			<!-- <div class="head-btn" @click="onSubmit">发布</div> -->
			<div :class="['middle-body-title',{'middle-body-title-active' : isShow == true }]" @click="onTitleListShow">
				<div class="middle-body-title-left">{{title}}</div>
				<div class="middle-body-title-right">
					<van-icon style="transform: rotate(90deg);" name="play" color="#777B81" v-if="isShow" />
					<van-icon name="play" color="#777B81" v-else />
				</div>
			</div>

			<div class="middle-body-list" v-show="isShow">
				<div class="middle-body-item" v-for="item in titleList" @click="onTitleList(item)">
					<div class="item-title">{{item.title}}</div>
					<div class="item-text">{{item.create_date}} {{item.create_week}}</div>
				</div>
			</div>

			<div class="middle-body-textarea">
				<textarea placeholder="请输入内容" v-model="content"></textarea>
				<div class="middle-footer-left">
					<div>({{ content.length }}/300)</div>
				</div>
			</div>

			<div class="uploader">
				<div class="uploader-item" v-for="(vo, index) in picture">
					<img class="uploader-close" src="@/assets/img/uploader_icon_close.png"
						@click="deletePicture(index)" />
					<img class="uploader-image" :src="vo.url" />
				</div>
				<div class="uploader-item uploader-item-select">
					<van-uploader :after-read="onSelect" v-show="isShowUploader" upload-icon="plus"></van-uploader>
				</div>
			</div>
			<div class="footer">
				<div class="footer-title">温馨提示</div>
				<div class="footer-body">
					<div class="footer-body-text">关联的活动只展示最近一周内未发布的5个活动，发布成功可获得5个爱心值；</div>
				</div>
				<div class="footer-title">注意事项</div>
				<div class="footer-body">
					<div class="footer-body-text">如有以下情形，平台有权清空发布的内容并禁用账号，严重者移交公安机关处理。</div>
					<div class="footer-body-text">1、严禁发布与活动无关的言论，严禁发布暴力恐怖血腥图片，禁止发布商业广告；</div>
					<div class="footer-body-text">2、严禁发布涉及政治敏感性话题或者具有社会不良影响的言论；</div>
					
				</div>
			</div>
			<div class="footer-button">
				<div class="footer-button-text" @click="onSubmit">发布义警圈</div>
			</div>
		</div>
	</div>
</template>

<script>
	import UploadApi from '@/api/upload';
	import Circle from '@/api/circle';
	import VantVendor from '@/vendor/vant';
	export default {
		name: 'AddCirle',
		inject: ['themeColor', 'className', 'isCity', 'isDistrict'],
		data() {
			return {
				picture: [],
				titleList: [],
				titleId: '',
				title: '请选择关联的活动',
				content: '',
				isShow: false,
				isShowUploader: true,
				isShowSelectGroup: true,
				needLoveValue: 0,
				taskDetail: ''
			};
		},
		watch: {
			content(newVal, oldVal) {
				if (newVal.length > 1000) {
					this.content = this.content.substring(0, 1000);
				}
			},
			picture(newVal, oldVal) {
				this.isShowUploader = newVal.length < 6;
			}
		},
		created() {
			Circle.activities()
				.then(result => {
					this.titleList = result.data
				}, error => {
					VantVendor.Toast.fail(error.msg)
				})
		},
		methods: {
			onTitleListShow() {
				if (this.titleList.length == 0) {
					VantVendor.Toast("您最近未参加活动，请先参加活动再来发布义警圈。")
				} else {
					this.isShow = true
				}
			},
			onTitleList(item) {
				this.isShow = false;
				this.title = item.title;
				this.titleId = item.activity_id;
			},
			onSubmit() {
				let params = {
					activity_id: this.titleId,
					content: this.content,
					photos: this.picture.map(item => item.path).join()
				};
				Circle.publish(params)
					.then(result => {
						VantVendor.Toast.success('发布成功爱心值+5');
						setTimeout(() => {
							this.$router.go(-1);
						}, 1000);
					}, error => {
						VantVendor.Toast.fail(error.msg)
					})
			},
			onSelect(selected) {
				VantVendor.Toast.loading('上传中');
				UploadApi.image(selected.file)
					.then(result => {
						this.picture.push({
							url: result.data.fileUrl,
							path: result.data.filePath
						});
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					})
					.finally(() => {
						VantVendor.Toast.clear();
					});
			},
			deletePicture(index) {
				this.picture.splice(index, 1);
			}
		}
	};
</script>

<style scoped lang="less">
	.AddCirle.city {
		.container{
			.head-text{
				color: #3377ff;
			}
			.footer{
				.footer-title{
					color: #3377ff;
				}
			}
			.footer-button{
				.footer-button-text{
					background: #3377FF;
				}
			}
		}
	}

	.AddCirle.district {
		.container{
			.head-text{
				color: #FF6969;
			}
			.footer{
				.footer-title{
					color: #FF6969;
				}
			}
			.footer-button{
				.footer-button-text{
					background: #FF6969;
				}
			}
		}
	}



	.AddCirle {
		position: relative;
		background-color: #fff;
		padding-bottom: 70px;
		min-height: 100vh;
		box-sizing: border-box;

		.container {
			padding: 10px;
			box-sizing: border-box;

			.head-text {
				font-weight: bold;
				font-size: 18px;
				line-height: 30px;
				margin-bottom: 4px;
			}

			.head-top {
				padding: 10px;
				padding-bottom: 0;
				background-color: #fff;
				border-radius: 6px;

				.head-middle {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 12px;

					.head-top-left {
						.head-top-text {
							font-size: 14px;
							line-height: 28px;
							color: #666;
						}

						.head-top-title {
							font-size: 18px;
							line-height: 26px;
							color: #000;
						}
					}

					.head-top-right {
						display: flex;
						justify-content: flex-start;
						align-items: center;

						.right-text {
							font-size: 14px;
							line-height: 26px;
							color: #666;
						}
					}
				}

				.head-top-bottom {
					margin-top: -20px;
					height: 13px;

					.head-top-bottom-picture {
						width: 100%;
						height: 2px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}

			.head-empty-address {
				width: 100%;
				height: 60px;
				line-height: 60px;
				background-color: #fff;
				border-radius: 6px;
				display: flex;
				justify-content: center;
				align-items: center;

				.head-empty-address-text {
					margin-left: 5px;
					font-size: 16px;
					color: #666;
				}

				.van-icon {
					font-size: 18px;
					color: #666;
				}
			}

			.middle-body-title {
				padding: 8px;
				background: #f5f6f8;
				border-radius: 4px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				box-sizing: border-box;

				.middle-body-title-left {
					font-size: 14px;
					line-height: 20px;
					color: #000;
				}

				&.middle-body-title-active {
					border-radius: 4px 4px 0px 0px;
				}
			}

			.middle-body-list {
				width: 355px;
				border: 1px solid #f2f2f2;
				border-top: 0;
				border-radius: 0px 0px 4px 4px;
				background-color: #fff;
				position: absolute;
				z-index: 10;
				box-sizing: border-box;

				.middle-body-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 12px 8px;
					border-bottom: 1px dashed #ddd;

					&.middle-body-item {
						border-bottom: 0;
					}

					.item-title {
						font-size: 14px;
						line-height: 20px;
						color: #222;
						width: 190px;
						max-width: 190px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.item-text {
						font-size: 14px;
						line-height: 20px;
						color: #777;
					}
				}
			}

			.middle-body-textarea {
				width: 100%;
				height: 100%;
				margin-top: 8px;
				padding: 10px;
				background-color: #f5f6f8;
				box-sizing: border-box;
				border-radius: 4px;
				position: relative;

				textarea {
					width: 100%;
					height: 224px;
					background-color: #f5f6f8;
					border: 0;
					font-size: 16px;
				}

				.middle-footer-left {
					position: absolute;
					right: 10px;
					bottom: 6px;
					font-size: 14px;
					line-height: 26px;
					color: #666;
				}
			}

			.uploader {
				display: flex;
				flex-wrap: wrap;
				margin-top: 8px;

				.uploader-item {
					position: relative;
					margin-right: 12px;

					&:last-child {
						margin-right: 0;
					}

					.uploader-close {
						position: absolute;
						top: 5px;
						right: 5px;
						width: 16px;
						height: 16px;
					}

					.uploader-image {
						width: 75px;
						height: 75px;
						object-fit: cover;
						border-radius: 6px;
					}
				}

				.uploader-item-select {
					position: relative;

					/deep/ .van-uploader__upload {
						width: 75px;
						height: 75px;
						margin: 0;
						border-radius: 6px;
						background-color: #f2f2f2;

						.van-icon {
							font-size: 30px;
							color: #aaa;
						}
					}
				}
			}

			.footer {
				.footer-title {
					font-weight: bold;
					font-size: 18px;
					line-height: 30px;
					margin-top: 16px;
				}

				.footer-body {
					margin-top: 8px;

					.footer-body-text {
						font-size: 14px;
						line-height: 25px;
						color: #777;
					}
				}
			}

			.footer-button {
				position: fixed;
				bottom: 20px;
				left: 0;
				padding: 0 10px;
				width: 100%;
				box-sizing: border-box;

				.footer-button-text {
					border-radius: 100px;
					padding: 10px 0;
					font-weight: 700;
					font-size: 18px;
					line-height: 30px;
					color: #FFF;
					text-align: center;
				}
			}
		}

	}
</style>
