import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 可发布动态的活动
const activities = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/activities', params)
}

// 添加义警圈
const publish = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/publish', params)
}

// 动态列表
const lists = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/lists', params)
}

// 点赞动态
const starMoment = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/starMoment', params)
}

// 取消动态点赞
const cancelStarMoment = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/cancelStarMoment', params)
}

// 点赞评论
const starComment = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/starComment', params)
}

// 取消评论点赞
const cancelStarComment = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/cancelStarComment', params)
}

// 关注
const subscribe = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/subscribe', params)
}

// 取消关注
const cancelSubscribe = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/cancelSubscribe', params)
}

// 详情
const detail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/detail', params)
}

// 主评论分页
const commentPages = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/comment/pages', params)
}

// 子评论分页
const subCommentPages = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/subComment/pages', params)
}

// 点赞列表
const starLists = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/star/lists', params)
}

// 提交评论
const commentCreate = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/comment/create', params)
}

// 举报
const report = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/report', params)
}

// 所有区县
const districtList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/district/lists', params)
}

// 删除动态
const momentDel = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/del', params)
}

// 分享
const momentShare = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/share', params)
}

// 删除评论
const commentDel = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/moment/comment/del', params)
}
export default {
	activities,
	publish,
	lists,
	starMoment,
	cancelStarMoment,
	starComment,
	cancelStarComment,
	subscribe,
	cancelSubscribe,
	detail,
	commentPages,
	subCommentPages,
	starLists,
	commentCreate,
	report,
	districtList,
	momentDel,
	momentShare,
	commentDel
}
